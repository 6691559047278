import React, { useState, useEffect } from "react";

export default function Background() {
  const root = document.querySelector(':root')
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [stars, setStars] = useState({
    smallBoxShadow: '',
    mediumBoxShadow: '',
    largeBoxShadow: '',
    media: ''
  });

  function createStars (amount, w, h, color) {
    let value = ""
    for(let i = 0; i < amount; i++) {
      if(i === 0){
        value = Math.floor(Math.random() * w) + 1 + "px " + Math.floor(Math.random() * h) + 1 + "px #" + color
      } else {
        value = value + "," + Math.floor(Math.random() * w) + 1 + "px " + Math.floor(Math.random() * h) + 1 + "px #" + color
      }
    }
    return value
  }

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

    useEffect(() => {
      if(windowWidth <= 600 ) {
        setStars((currentStars) => {
          if (currentStars.media === 'Mobile') {
            return currentStars
          } else {
            currentStars.smallBoxShadow = createStars(700, 100, 200, 'fff')
            currentStars.mediumBoxShadow = createStars(100, 100, 200, 'fff')
            currentStars.largeBoxShadow = createStars(50, 100, 200, 'fff')
            root.style.setProperty('--smallStars', currentStars.smallBoxShadow)
            root.style.setProperty('--mediumStars', currentStars.mediumBoxShadow)
            root.style.setProperty('--largeStars', currentStars.largeBoxShadow)
            return {...currentStars, media: 'Mobile'}
          }
        })
      } else if (windowWidth > 600 && windowWidth <= 2000) {
        setStars((currentStars) => {
          if (currentStars.media === 'Desktop') {
            return currentStars
          } else {
            currentStars.smallBoxShadow = createStars(700, 200, 200, 'fff')
            currentStars.mediumBoxShadow = createStars(100, 200, 200, 'fff')
            currentStars.largeBoxShadow = createStars(50, 200, 200, 'fff')
            root.style.setProperty('--smallStars', currentStars.smallBoxShadow)
            root.style.setProperty('--mediumStars', currentStars.mediumBoxShadow)
            root.style.setProperty('--largeStars', currentStars.largeBoxShadow)
            return {...currentStars, media: 'Desktop'}
          }
        })
      } else if (windowWidth > 2000) {
        setStars((currentStars) => {
          if (currentStars.media === 'Ultrawide') {
            return currentStars
          } else {
            currentStars.smallBoxShadow = createStars(700, 400, 200, 'fff')
            currentStars.mediumBoxShadow = createStars(100, 400, 200, 'fff')
            currentStars.largeBoxShadow = createStars(50, 400, 200, 'fff')
            root.style.setProperty('--smallStars', currentStars.smallBoxShadow)
            root.style.setProperty('--mediumStars', currentStars.mediumBoxShadow)
            root.style.setProperty('--largeStars', currentStars.largeBoxShadow)
            return {...currentStars, media: 'Ultrawide'}
          }
        })
      } else {
        console.log('Something went wrong')
      }
  }, [windowWidth, stars, root.style])
  return (
    <div className='background'>
      <div className="s-stars"></div>
      <div className="m-stars"></div>
      <div className="l-stars"></div>
      <div className="death-star">
        <div className="shadow"></div>
        <div className="circle"></div>
        <div className="trait"></div>
      </div>
    </div>
  )
}