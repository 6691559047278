import React, { useRef, useState } from "react"
import { useAuth } from '../contexts/AuthContext'
import { Link } from 'react-router-dom'
import { TabTitle } from "../utils/GeneralFunctions"

export default function ForgotPassword() {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  TabTitle('Forgot Password | Jeroen Meij IT')

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError('')
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage('Check your inbox for further instructions')
    } catch(error) {
      setError(error.message)
    }
    setLoading(false)
  }

  return (
    <>
      <div className="card">
        <header className="card-header">
          <h2 className="card-title">Password Reset</h2>
          {error !== '' && <div className="alert-box" role="alert">{error}</div>}
          {message !== '' && <div className="message-box" role="alert">{message}</div>}
        </header>
        <div className="card-body">
          <form className='authorization-form' onSubmit={handleSubmit}>
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" ref={emailRef} required/>
            <button disabled={loading} className='btn btn-blue' type='submit'>Reset Password</button>
          </form>
          <div className="forgot-password">
            <Link className="link" to="/login">Back to Login</Link>
          </div>
        </div>
      </div>
      <div className="switch-account">
        Need an account?<Link className="link ml-1" to="/signup"> Sign Up</Link>
      </div>
    </>
  )
}