import React, { useRef, useState } from "react"
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { TabTitle } from "../utils/GeneralFunctions";

export default function UpdateProfile() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { currentUser, updateEmail, updatePassword } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  TabTitle('Update Profile | Jeroen Meij IT')

  function handleSubmit(e) {
    e.preventDefault()
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match')
    }

    const promises = []
    setLoading(true)
    if (emailRef.current.value !== currentUser.email) {
      promises.push(updateEmail(emailRef.current.value))
    }
    if (passwordRef.current.value !== currentUser.email) {
      promises.push(updatePassword(passwordRef.current.value))
    }

    Promise.all(promises).then(() => {
      navigate('/')
    }).catch((error) => {
      setError(error)
    }).finally(() => {
      setLoading(false)
    })
  }

  return (
    <>
      <div className="card">
        <header className="card-header">
          <h2 className="card-title">Update Profile</h2>
          {error !== '' &&
            <div className="alert-box" role="alert">{error}</div>
          }
        </header>
        <div className="card-body">
          <form className='authorization-form' onSubmit={handleSubmit}>
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" ref={emailRef} defaultValue={currentUser.email} required/>
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" ref={passwordRef} placeholder="Leave blank to keep the same"/>
            <label htmlFor="password-confirm">Password confirm</label>
            <input type="password" id="password-confirm" name="password-confirm" ref={passwordConfirmRef} placeholder="Leave blank to keep the same"/>
            <button disabled={loading} className='btn btn-blue' type='submit'>Update Profile</button>
          </form>
        </div>
      </div>
      <div className="switch-account">
        <Link className="link" to="/">Cancel</Link>
      </div>
    </>
  )
}