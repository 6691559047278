import './Hangman.css'

export default function Drawing({guessedLetters, count, wordToGuess}) {
  const head = <div><div className="drawing-head"/></div>
  const body = <div><div className="drawing-body"/></div>
  const leftArm = <div><div className="drawing-left-arm"/></div>
  const rightArm = <div><div className="drawing-right-arm"/></div>
  const leftLeg = <div><div className="drawing-left-leg"/></div>
  const rightLeg = <div><div className="drawing-right-leg"/></div>

  return (
    <div style={{ position: "relative" }}>
      {count > 0 ? head : ''}
      {count > 1 ? body : ''}
      {count > 2 ? leftArm : ''}
      {count > 3 ? rightArm : ''}
      {count > 4 ? leftLeg : ''}
      {count > 5 ? rightLeg : ''}
      <div className="drawing-rope"/>
      <div className="drawing-topbar"/>
      <div className="drawing-basebar"/>
      <div className="drawing-foot"/>
    </div>
  )
}