import React, { useRef, useState } from "react"
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { TabTitle } from "../utils/GeneralFunctions";

export default function Login() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const { login } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  TabTitle('Login | Jeroen Meij IT')

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setError('')
      setLoading(true)
      await login(emailRef.current.value, passwordRef.current.value)
      navigate("/")
    } catch(error) {
      setError(error.message)
    }
    setLoading(false)
  }

  return (
    <>
      <div className="card">
        <header className="card-header">
          <h2 className="card-title">Log In</h2>
          {error !== '' &&
            <div className="alert-box" role="alert">{error}</div>
          }
        </header>
        <div className="card-body">
          <form className='authorization-form' onSubmit={handleSubmit}>
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" ref={emailRef} required/>
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" ref={passwordRef} required/>
            <button disabled={loading} className='btn btn-blue' type='submit'>Log In</button>
          </form>
          <div className="forgot-password">
            <Link className="link" to="/forgot-password">Forgot password?</Link>
          </div>
        </div>
      </div>
      <div className="switch-account">
        Need an account? <Link className="link ml-1" to="/signup">Sign Up</Link>
      </div>
    </>
  )
}