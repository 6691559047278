import React, { useRef, useState } from "react"
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { TabTitle } from "../utils/GeneralFunctions";

export default function Signup() {
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const { signup } = useAuth()
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  TabTitle('Sign Up | Jeroen Meij IT')

  async function handleSubmit(e) {
    e.preventDefault()

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match')
    }

    try {
      setError('')
      setLoading(true)
      await signup(emailRef.current.value, passwordRef.current.value)
      navigate("/")
    } catch(error) {
      setError(error.message)
    }
    setLoading(false)
  }

  return (
    <>
      <div className="card">
        <header className="card-header">
          <h2 className="card-title">Sign Up</h2>
          {error !== '' &&
            <div className="alert-box" role="alert">{error}</div>
          }
        </header>
        <div className="card-body">
          <form className='authorization-form' onSubmit={handleSubmit}>
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" ref={emailRef} required/>
            <label htmlFor="password">Password</label>
            <input type="password" id="password" name="password" ref={passwordRef} required/>
            <label htmlFor="password-confirm">Password confirm</label>
            <input type="password" id="password-confirm" name="password-confirm" ref={passwordConfirmRef} required/>
            <button disabled={loading} className='btn btn-blue' type='submit'>Sign up</button>
          </form>
        </div>
      </div>
      <div className="switch-account">
        Already have an account? <Link className="link ml-1" to="/login">Log In</Link>
      </div>
    </>
  )
}