import React, { useState} from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { TabTitle } from "../utils/GeneralFunctions";

export default function Dashboard() {
  const [error, SetError] = useState("")
  const { currentUser, logout } = useAuth()
  const navigate = useNavigate()

  TabTitle('Dashboard | Jeroen Meij IT')

  async function handleLogout() {
    SetError("")

    try {
      await logout()
      navigate("/login")
    } catch (error) {
      SetError(error)
    }
  }

  return (
    <>
      <div className="card">
        <header className="card-header">
          <h2 className="card-title">Profile</h2>
          {error !== '' && <div className="alert-box" role="alert">{error}</div> }
        </header>
        <div className="card-body">
          <strong>Email: </strong> {currentUser.email}
          <Link className="btn btn-blue" to="/update-profile">Update Profile</Link>
        </div>
      </div>
      <div className="switch-account">
        <button className='btn btn-transparant' onClick={handleLogout}>Log Out</button>
      </div>
    </>
  )
}