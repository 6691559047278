import React, { useEffect, useState } from "react"
import words from "../../utils/words-nl.json"
import Drawing from "./Drawing"
import Word from "./Word"
import Keyboard from "./Keyboard"
import { TabTitle } from "../../utils/GeneralFunctions"

export default function Hangman() {
  const [status, setStatus] = useState('Initial')
  const [count, setCount] = useState(0)
  const [wordToGuess, setWordToGuess] = useState(() => {
    return words[Math.floor(Math.random() * words.length)]
  })
  const [guessedLetters, setGuessedLetters] = useState([])

  const submitLetter = (sumbittedLetter) => {
    setGuessedLetters(oldArray => [...oldArray, sumbittedLetter])
  }

  function resetGame(newStatus) {
    setCount(0)
    setWordToGuess(words[Math.floor(Math.random() * words.length)])
    setGuessedLetters([])
    if(newStatus === 'new') {
      setStatus("Active")
    } else {
      setStatus("Initial")
    }
  }

  useEffect(() => {
    let c = 0
    const splittedWord = wordToGuess.split("")
    for(let i in guessedLetters) {
      if (!wordToGuess.includes(guessedLetters[i])) {
        c++;
      }
      setCount(c)
    }

    if (guessedLetters.length > 0 && splittedWord.every(l => guessedLetters.includes(l))) {
      setStatus('Success')
    } else if (count > 6 && !splittedWord.includes(guessedLetters[guessedLetters.length - 1])){
      setStatus('Failed')
    }
  },[count, guessedLetters, wordToGuess]);

  TabTitle('Hangman | Jeroen Meij IT')

  return (
    <>
      <div className="card">
        <header className="card-header">
          <h2 className="card-title">Hangman</h2>
        </header>
        {
          status === 'Initial' && 
          <div className="card-body">
            <div className="menu">
              <button onClick={() => setStatus('Active')}>New Game</button>
              <button onClick={() => setStatus('Active')}>Highscore</button>
              <button onClick={() => setStatus('Active')}>Settings</button>
              <button onClick={() => setStatus('Active')}>Back to Home</button>
            </div>
          </div>
        }
        {
          status === 'Active' &&
          <div className="card-body">
            <Drawing count={count} guessedLetters={guessedLetters} wordToGuess={wordToGuess}/>
            <Word guessedLetters={guessedLetters} wordToGuess={wordToGuess}/>
            <Keyboard guessedLetters={guessedLetters} wordToGuess={wordToGuess} submitLetter={submitLetter}/>
          </div>
        }
        {
          status === 'Success' &&
          <div className="card-body">
            <div className="result-text">
              <p>The word:</p>
              <div className="result-word">
                <p>{wordToGuess}</p>
              </div>
              <p>You guessed it!</p>
              { count === 5 &&
              <p>You had 1 try left.</p>
              }
              { count !== 5 &&
              <p>You had {6 - count} tries left.</p>
              }
            </div>
            <div className="menu">
              <button onClick={() => resetGame('new')}>Play Again</button>
              <button onClick={() => resetGame('menu')}>Back to Menu</button>
            </div>
          </div>
        }
        {
          status === 'Failed' &&
          <div className="card-body">
            <div className="result-text mb-10">
              <p>The word:</p>
              <div className="result-word">
                <p>{wordToGuess}</p>
              </div>
              <p>You did not guess it! :(</p>
            </div>
            <div className="menu">
              <button onClick={() => resetGame('new')}>Play Again</button>
              <button onClick={() => resetGame('menu')}>Back to Menu</button>
            </div>
          </div>
        }
      </div>
    </>
  )
}