import './Hangman.css'

export default function Keyboard({guessedLetters, wordToGuess, submitLetter}) {
  const alphabet = [
    ['q','w','e','r','t','y','u','i','o','p'],
    ['a','s','d','f','g','h','j','k','l'],
    ['z','x','c','v','b','n','m']
  ]
  return (
    <div className="keyboard-wrapper">
      {
        alphabet.map((row, rowIndex) => {
          return (
            <div className="keyboard-row" key={"keyboard-row-" + rowIndex}>
              {
              alphabet[rowIndex].map((letter, letterIndex) => {
                return <button key={"keyboard-" + letterIndex} className={wordToGuess.includes(letter) && guessedLetters.includes(letter) ? "keyboard-button correct" : "keyboard-button"} disabled={guessedLetters.find(l => l === letter) ? true : false} onClick={() => submitLetter(letter)}>{letter}</button>
              })
            }
            </div>
          )
        })
      }
    </div>
  )
}