import './App.css'
import Background from './Background'
import { AuthProvider } from '../contexts/AuthContext'
import { Link } from 'react-router-dom'
import Dashboard from './Dashboard'
import Welcome from './Welcome'
import UpdateProfile from './UpdateProfile'
import Login from './Login'
import { ReactComponent as Logo } from './Svg/Logo.svg';
import Signup from "./Signup"
import ForgotPassword from "./ForgotPassword"
import Hangman from "./Hangman/Hangman"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import RequireAuth from './RequireAuth';

function App() {
  return (
    <>
      <Background/>
      <Router>
        <AuthProvider>
          <header className="nav-bar">
            <Link to="/">
              <Logo fill="white" width="32" className="logo"/>
            </Link>
            <nav className="mx-5">
              <ul className="nav-bar-items">
                <li className="text-white">
                  <Link className="nav-button" to="/login">Sign in</Link>
                </li>
              </ul>
            </nav>
          </header>
          <main className='container'>
            <div className='content'>
            <Routes>
              <Route path="/" element={<Welcome />} />\
              <Route path="/dashboard" element={<RequireAuth redirectTo="/login"><Dashboard /></RequireAuth>} />
              <Route path="/update-profile" element={<RequireAuth redirectTo="/login"><UpdateProfile /></RequireAuth>} />
              <Route path="/signup" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/hangman" element={<Hangman />} />
              <Route path="*" element={<h1>404 | Not Found</h1>} />
            </Routes>
            </div>
          </main>
        </AuthProvider>
      </Router>
    </>
  )
}

export default App;