import './Hangman.css'

export default function Word({guessedLetters, wordToGuess}) {
  const wordArray = wordToGuess.split("")
  return (
    <div style={{ display: "flex", gap: "10px", justifyContent: "center", margin: "20px 0"}}>
      {
        wordArray.map((letter, i) => {
          return <span key={i} align="center" style={{ display: "block", borderBottom: "2px solid black", fontSize: "32px", height: "50px", width: "50px", textTransform: "uppercase"}}>
            {guessedLetters.find(l => l === letter) ? letter : ''}
          </span>
        })
      }
    </div>
  )
}